import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pw-drawer-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pw-drawer-content.component.html',
  styleUrl: './pw-drawer-content.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerContentComponent {}

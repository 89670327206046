<pw-drawer-header-ui (closed)="onClose()">
  @if($back(); as prevDrawer) {
    <ng-container slot="start">
      <button mat-button style="color: currentColor" (click)="onBack()" type="button">
        <mat-icon>arrow_back</mat-icon>
        Back to {{prevDrawer.name}}
      </button>
    </ng-container>
  }
  <ng-content />
</pw-drawer-header-ui>

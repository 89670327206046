import { computed, Injectable, Injector, signal } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Drawer, DrawerProps } from './ts/type';
import { DrawerDataInjector } from './ts/const';

@Injectable({
  providedIn: 'root',
})
export class PwDrawerService {
  /**
   * A signal that is emitted once the drawer itself requested to close.
   * @private
   */
  #$drawerSelfClose = signal(true);
  $drawerStack = signal<Drawer<unknown, unknown>[]>([]);

  $previousDrawer = computed(() =>
    this.$drawerStack().length > 1 ? this.$drawerStack().at(-2) : null,
  );

  $drawer = computed(() =>
    this.$drawerStack().length > 0 ? this.$drawerStack().at(-1) : null,
  );

  $isOpen = computed(
    () => !this.#$drawerSelfClose() && this.$drawer() !== null,
  );

  #createInjector(injector: Injector, data: unknown) {
    return Injector.create({
      parent: injector,
      providers: [
        {
          provide: DrawerDataInjector,
          useValue: data,
        },
      ],
    });
  }
  open<TComponent, TData>(
    component: ComponentType<TComponent>,
    injector: Injector,
    options: Omit<DrawerProps<TData>, 'component' | 'injector'>,
  ) {
    this.#$drawerSelfClose.set(false);
    const injector1 = this.#createInjector(injector, options.data);
    this.$drawerStack.update((stack) => {
      return [
        ...stack,
        {
          component,
          injector: injector1,
          ...options,
        },
      ];
    });
  }

  close() {
    this.#$drawerSelfClose.set(true);
  }

  onClose() {
    this.$drawerStack.set([]);
  }

  goBack() {
    this.$drawerStack.update((stack) => {
      stack.pop();
      return [...stack];
    });
  }
}

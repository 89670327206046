import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { useAttribute } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-drawer-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pw-drawer-container.component.html',
  styleUrl: './pw-drawer-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerContainerComponent {
  size = input.required<'s' | 'm' | 'l' | 'xl'>();

  @HostBinding('attr.size') get sizeClass() {
    return this.size();
  }

  constructor() {
    const { set } = useAttribute('size');
    effect(() => {
      set(this.size());
    });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwDrawerService } from '../pw-drawer.service';
import { DrawerHeaderUiComponent } from '../ui';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'pw-drawer-header',
  standalone: true,
  imports: [
    CommonModule,
    DrawerHeaderUiComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './pw-drawer-header.component.html',
  styleUrl: './pw-drawer-header.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerHeaderComponent {
  #drawerService = inject(PwDrawerService);

  $back = this.#drawerService.$previousDrawer;
  onClose() {
    this.#drawerService.close();
  }

  onBack() {
    window.history.back();
  }
}

import { ElementRef, inject, Renderer2 } from '@angular/core';

export function useAttribute(attr: string) {
  const element = inject(ElementRef);
  const renderer = inject(Renderer2);
  return {
    set: (value: string) =>
      renderer.setAttribute(element.nativeElement, attr, value),
  };
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/**
 * @slots
 * - start - start content
 * - default - main content
 */
@Component({
  selector: 'pw-drawer-header-ui',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatButtonModule, MatIconModule],
  template: `
    <div class="start">
      <ng-content select="[slot=start]" />
    </div>
    <div class="content">
      <h3><ng-content /></h3>
    </div>
    <button mat-icon-button (click)="onClose()" class="end" type="button">
      <mat-icon>close</mat-icon>
    </button>
  `,
  styles: `
    :host {
      padding: var(--pw-drawer-padding);
      background-color: var(--pw-accent-color);
      color: var(--pw-text-secondary);
      height: var(--pw-distance-16);

      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      gap: var(--pw-gap-xl);
  }

  .start {
    justify-self: start;
  }
  .content {
    justify-self: center;
  }
  .end {
    justify-self: end;
  }
  `,
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerHeaderUiComponent {
  @Output()
  closed = new EventEmitter<void>();

  onClose() {
    this.closed.emit();
  }
}
